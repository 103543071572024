export const FILTER_CHANGE = 'FILTER_CHANGE'
export const QUESTION_CHANGE = 'QUESTION_CHANGE'
export const FIRST_QUESTION_CHANGE = 'QUESTION_CHANGE'
export const SECOND_QUESTION_CHANGE = 'QUESTION_CHANGE'
export const EXPLORER_CHANGE = 'EXPLORER_CHANGE'
export const EXPLORER_COUNTS = 'EXPLORER_COUNTS'
export const EXPLORER_REQUEST = 'EXPLORER_REQUEST'
export const QUESTION_REQUEST = 'QUESTION_REQUEST'

export const MID_GRAPH = 'MID_GRAPH'
export const LEFT_GRAPH = 'LEFT_GRAPH'
export const RIGHT_GRAPH = 'RIGHT_GRAPH'

export const FILTER_QUESTION_REQUEST = 'FILTER_QUESTION_REQUEST'
export const FILTER_REQUEST = 'FILTER_REQUEST'

export const FILTER_REQUEST_ONE = 'FILTER_REQUEST_ONE'
export const FILTER_REQUEST_TWO = 'FILTER_REQUEST_TWO'
export const FILTER_REQUEST_THREE = 'FILTER_REQUEST_THREE'
