import { FILTER_CHANGE, FILTER_REQUEST,FILTER_REQUEST_TWO,FILTER_REQUEST_THREE,FILTER_REQUEST_ONE,
    QUESTION_CHANGE, EXPLORER_CHANGE, EXPLORER_REQUEST, QUESTION_REQUEST, FILTER_QUESTION_REQUEST, MID_GRAPH, LEFT_GRAPH, RIGHT_GRAPH, EXPLORER_COUNTS } from './constant';

const initialState = {
   changeFilterData: {},
   questionData: [],
   dataExplorer: [],
   dataCounts:[],
   explorerReq: [
       {
           "question_variable_name": "",
           year: '',
           "filters": {}
       },
       {
           "question_variable_name": "",
           year: '',
           "filters": {}
       },
       {
           "question_variable_name": "",
           year: '',
           filters: {}
       }
   ],
   first_question_add: false,
   second_question_add: true,
   third_question_add: false,
   questionChoice: 2,
   showMid:true,
   showLeft: true,
   showRight:true,
   filterChecks: [ 1],
   activeFilterData: [{}, {}, {}],
   filterData0:{},
   filterData1:{},
   filterData2:{},
};


function homeReducer(state = initialState, action) {
   switch (action.type) {
       case FILTER_CHANGE:
           return Object.assign({}, state, {
               changeFilterData: action.data,
           })
       case QUESTION_CHANGE:
           return Object.assign({}, state, {
               questionData: action.data,
           })
       case EXPLORER_CHANGE:
           return Object.assign({}, state, {
               dataExplorer: action.data,
           });

       case EXPLORER_COUNTS:
            return Object.assign({}, state, {
                dataCounts: action.data,
            })
       case EXPLORER_REQUEST:
           return Object.assign({}, state, {
               explorerReq: action.data,
           })
       case QUESTION_REQUEST:
           return Object.assign({}, state, {
               questionChoice: action.data,
           })
       case FILTER_QUESTION_REQUEST:
           return Object.assign({}, state, {
               filterChecks: action.data,
           })
       case MID_GRAPH:
           return Object.assign({}, state, {
               showMid: action.data,
           })
       case LEFT_GRAPH:
           return Object.assign({}, state, {
               showLeft: action.data,
           })
       case RIGHT_GRAPH:
           return Object.assign({}, state, {
               showRight: action.data,
           })
       case FILTER_REQUEST:
           return Object.assign({}, state, {
               activeFilterData: action.data,
           })
           case FILTER_REQUEST_ONE:
           return Object.assign({}, state, {
               filterData0: action.data,
           })
           case FILTER_REQUEST_TWO:
           return Object.assign({}, state, {
               filterData1: action.data,
           })
           case FILTER_REQUEST_THREE:
           return Object.assign({}, state, {
               filterData2: action.data,
           })
       default:
           return state;
   }
};

export default homeReducer;