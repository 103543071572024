import React from 'react';

const HomePage = React.lazy(() => import('./Demo/HomePage'));
const QuestionsPage = React.lazy(() => import('./Demo/QuestionsPage'));

const route = [
    { path: '/', exact: true, name: 'Home Page', component: HomePage },
    { path: '/questions/:id', exact: true, name: 'Questions Page', component: QuestionsPage },
];

export default route;